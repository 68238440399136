export default class Complaint {
  id;
  target;
  event;
  contact;
  result;
  reporter;
  createdAt;

  constructor(data) {
    const {
      id,
      target,
      event,
      contact,
      result,
      reporter,
      createdAt
    } = data;

    this.id = id;

    this.target = {
      name: target ? target.name : null,
      phone: target ? target.phone : null,
      idCard: target ? target.idCard : null
    };

    this.event = {
      date: event ? event.date : null,
      location: event ? event.location : null,
      issues: event ? event.issues : [],
      description: event ? event.description : null,
      files: event ? event.files : []
    };

    this.contact = {
      name: contact ? contact.name : null,
      email: contact ? contact.email : null
    };

    this.result = {
      status: result ? result.status : null,
      level: result ? result.level : null,
      date: result ? result.date : null,
      description: result ? result.description : null,
      matchPercentage: result ? result.matchPercentage : null,
      files: result ? result.files : []
    };

    this.reporter = {
      id: reporter ? reporter.id : null,
      name: reporter ? reporter.name : null,
      nickname: reporter ? reporter.nickname : null,
      phone: reporter ? reporter.phone : null
    };

    this.createdAt = createdAt;
  }

  static fromRes(data) {
    return new Complaint(data);
  }
}
