import { useCallback, useEffect, useState } from 'react';
import { computed, runInAction } from 'mobx';
import { useLocalObservable, observer } from 'mobx-react';
import clsx from 'clsx';
import { useFilePicker } from 'use-file-picker';
import PropTypes from 'prop-types';
import { Button, Collapse, Form, Input, message, Select } from 'antd';
import dayjs from 'dayjs';
import { ComplaintLevelTxt, ComplaintStatus, Router } from 'src/constants';

import { ReactComponent as AttachmentIcon } from 'src/assets/attachment.svg';
import { ReactComponent as ArrowDownIcon } from 'src/assets/arrowDown.svg';
import withProfile from 'src/components/withProfile';
import BackstageBreadcrumb from 'src/components/BackstageBreadcrumb';

import FileSizeValidator from 'src/pages/client/Message/FileSizeValidator';
import ComplaintService from 'src/services/complaint';
import infoStore from 'src/stores/infoStore';
import styles from './styles.module.scss';
import { FileCard, Attachment } from './Upload';
import ComplaintContentTable from '../../../components/ComplaintContentTable';

const MaxFiles = 10;

const ComplaintDetailPage = observer((props) => {
  const { router } = props;
  const complaintId = router.params?.complaintId;
  const [form] = Form.useForm();
  const [isEdited, setIsEdited] = useState(false);

  const store = useLocalObservable(() => ({
    complaint: null,
    complaintCache: null,
    isPageLoading: false,
    isUpdatingData: {
      [ComplaintStatus.InProcess]: false,
      [ComplaintStatus.Completed]: false
    },
    files: {},
    filesCache: {},
    get resultStatus() {
      return this.complaint?.result?.status;
    },
    get sendable() {
      const files = Object.values(this.files).filter((el) => !!el);

      // 至少有一項內容
      const count = [
        Math.min(files.length, 1)
      ].reduce((p, c) => p + c, 0);

      return count > 0
        // 確保狀態皆為 done
        && (files.length ? files.every((el) => el.status === 'done') : true);
    }
  }));

  useEffect(() => {
    // fetch complaint data
    const fetchComplaint = async () => {
      try {
        runInAction(() => { store.isPageLoading = true; });
        const complaint = await ComplaintService.getDetailBackstage(complaintId);

        runInAction(() => {
          store.complaint = complaint;
          store.complaintCache = { ...store.complaint };

          for (const file of complaint.result.files) {
            store.files[file.name] = { ...file, origin: 'server' };
          }
          store.filesCache = { ...store.files };
        });

        if (complaint.result.level || complaint.result.description || complaint.result.matchPercentage) {
          form.setFieldsValue({
            level: complaint.result.level,
            description: complaint.result.description,
            matchPercentage: complaint.result.matchPercentage
          });
        }

      } catch {
        infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
      } finally {
        runInAction(() => { store.isPageLoading = false; });
      }
    };

    fetchComplaint();
  }, [store, complaintId, form]);

  const onFilesRejected = useCallback(
    ({ errors }) => {
      const [error] = errors; // only show the first error
      switch (error?.name) {
        case 'FileSizeValidatorError':
          message.open({ type: 'warning', content: error.reason });
          break;
        default:
          message.open({ type: 'warning', content: '未知錯誤' });
          break;
      }
    },
    []
  );

  const onFileSelected = useCallback(
    ({ plainFiles }) => runInAction(() => {
      if (!plainFiles?.length) {
        return;
      }
      for (const file of plainFiles) {
        store.files[file.name] = Attachment.editmode(complaintId, file);
      }

      setIsEdited(true);
    }),
    [complaintId, store.files]
  );

  const filePicker = useFilePicker({
    accept: [
      'image/png',
      'image/jpeg',
      'image/bmp',
      'video/mp3',
      'audio/mp3',
      'video/mp4',
      'audio/mp4',
      'application/pdf'
    ],
    multiple: true,
    validators: [
      new FileSizeValidator(
        10 * 1024 * 1024, // default = 10MB,
        {
          'video/mp3': 25 * 1024 * 1024,
          'video/mp4': 25 * 1024 * 1024 // mp4 = 25MB
        }
      )
    ],
    onFilesSelected: onFileSelected,
    onFilesRejected
  });

  const onFileRemove = useCallback((item) => runInAction(() => {
    store.files[item.origin === 'server' ? item.name : item.file.name] = null;
    setIsEdited(true);
  }), [store.files]);

  const updateResult = async (data) => {
    try {
      runInAction(() => { store.isUpdatingData[data.status] = true; });
      const updatedComplaint = await ComplaintService.updateResult(complaintId, data);
      runInAction(() => {
        store.complaint = updatedComplaint;
        store.complaintCache = { ...store.complaint };
        store.filesCache = { ...store.files };
      });
      message.open({
        type: 'success',
        content: data.status === ComplaintStatus.InProcess ? '已儲存現階段調查狀態。若確認調查完畢可結案，請按下送出。' : '更新成功',
        duration: 5
      });
    } catch (error) {
      console.error(error);
      message.open({ type: 'error', content: '發生錯誤，無法更新' });
    } finally {
      runInAction(() => { store.isUpdatingData[data.status] = false; });
    }
  };

  const getDataToPut = (status) => {
    const formData = form.getFieldsValue();
    return {
      status,
      date: dayjs().toISOString(),
      level: formData.level,
      description: formData.description,
      matchPercentage: formData.matchPercentage,
      files: Object.values(store.files).filter((el) => !!el).map((el) => ({
        size: el.origin === 'server' ? el.size : el.file.size,
        name: el.origin === 'server' ? el.name : el.id
      }))
    };
  };

  const onUpdateResultStatus = async (status) => {
    await updateResult({
      status,
      date: dayjs().toISOString()
    });
  };

  const onSaveResult = async () => {
    try {
      await form.validateFields(['matchPercentage']);
      const dataToSave = getDataToPut(ComplaintStatus.InProcess);
      await updateResult(dataToSave);
      setIsEdited(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmitResult = async () => {
    try {
      await form.validateFields();
      const dataToSubmit = getDataToPut(ComplaintStatus.Completed);
      await updateResult(dataToSubmit);
      setIsEdited(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onCancelChange = () => {
    runInAction(() => {
      store.complaint = { ...store.complaintCache };
      store.files = { ...store.filesCache };
    });
    form.setFieldsValue({
      level: store.complaintCache.result.level,
      description: store.complaintCache.result.description,
      matchPercentage: store.complaintCache.result.matchPercentage
    });
    message.open({ type: 'info', content: '已取消變更' });
    setIsEdited(false);
  };

  const fileList = computed(() => Object.values(store.files).filter((el) => !!el)).get();

  const renderResultSection = () => {
    if (store.resultStatus === ComplaintStatus.NotStarted) {
      return null;
    }

    const items = [
      {
        key: '1',
        label: (<div className={styles.sectionTitle}>平台調查結果</div>),
        children: (
          <div>
            <Form
              form={form}
              className={styles.form}
              layout="vertical"
              onValuesChange={() => setIsEdited(true)}
            >
              <div className={styles.formRow}>
                <Form.Item
                  name="matchPercentage"
                  label="資料吻合程度"
                  rules={[
                    { required: true, message: '請輸入資料吻合程度' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || (value >= 0 && value <= 100)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('請輸入 0 ~ 100 之間的數值'));
                      }
                    })
                  ]}
                  className={styles.matchPercentageWrapper}
                >
                  <Input
                    placeholder="請輸入數值"
                    type="number"
                    min={0}
                    max={100}
                    suffix="%"
                  />
                </Form.Item>
                <Form.Item
                  name="level"
                  label="嚴重程度"
                  rules={[{ required: true, message: '請選擇嚴重程度' }]}
                >
                  <Select
                    placeholder="請選擇"
                    options={Object.keys(ComplaintLevelTxt).map((status) => ({ value: status, label: ComplaintLevelTxt[status] }))}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="description"
                label="結果描述"
                rules={[{ required: true, message: '請填寫結果描述' }]}
              >
                <Input.TextArea
                  rows={6}
                  showCount
                  maxLength={2000}
                />
              </Form.Item>

            </Form>

            <div className={clsx(styles.uploadFilePanel)}>
              <div className={styles.uploadButtonAndNote}>
                <Button
                  className={styles.button}
                  size="large"
                  icon={<AttachmentIcon />}
                  onClick={filePicker.openFilePicker}
                />
                <div className={styles.tips}>
                  上傳公開事件舉證影像/檔案（上限10個檔案，.jpg, .png, .pdf, .mp3, .mp4）
                </div>
              </div>

              {
                fileList.length ? (
                  <div className={styles.files}>
                    {
                      fileList.map((item, idx) => (
                        <FileCard
                          key={`file-${item.key || item.name}`}
                          item={item}
                          isLengthLimitReached={(idx + 1) > MaxFiles}
                          onClear={onFileRemove}
                        />
                      ))
                    }
                  </div>
                ) : null
              }
            </div>

            {renderResultFormButtons()}
          </div>
        )
      }
    ];

    const renderExpandIcon = ({ isActive }) => <ArrowDownIcon style={{ transform: `rotate(${isActive ? '180deg' : '0deg'})` }} />;

    return (
      <section className={styles.resultSection}>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={renderExpandIcon}
          style={{
            background: '#f5f5f5'
          }}
          items={items}
        />
      </section>
    );
  };

  const renderResultFormButtons = () => {
    switch (store.resultStatus) {
      case ComplaintStatus.InProcess:
        return (
          <div className={styles.formButtons}>
            <Button
              type="primary"
              className={styles.formButton}
              onClick={onSaveResult}
              loading={store.isUpdatingData[ComplaintStatus.InProcess]}
            >
              暫存
            </Button>
            <Button
              type="primary"
              className={styles.formButton}
              onClick={onSubmitResult}
              loading={store.isUpdatingData[ComplaintStatus.Completed]}
            >
              送出
            </Button>
          </div>
        );
      case ComplaintStatus.Completed:
        return (
          <div className={styles.formButtons}>
            <div className={styles.cancelButtonWrapper}>
              <Button
                type="default"
                className={styles.formButton}
                onClick={onCancelChange}
                disabled={!isEdited}
              >
                取消
              </Button>
              <div className={styles.cancelButtonNote}>
                若按下取消，內容將會恢復為您前一次的輸入結果
              </div>
            </div>
            <Button
              type="primary"
              className={styles.formButton}
              onClick={onSubmitResult}
              loading={store.isUpdatingData[ComplaintStatus.Completed]}
            >
              更新
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const renderBottomButton = () => {
    if (store.resultStatus !== ComplaintStatus.NotStarted) {
      return null;
    }

    return (
      <div className={styles.bottomButtonContainer}>
        <Button
          type="primary"
          onClick={() => onUpdateResultStatus(ComplaintStatus.InProcess)}
        >
          更改狀態為調查中
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <BackstageBreadcrumb
          items={[
            { title: '黑名單舉報管理', url: Router.Backstage.Complaint },
            { title: '詳情' }
          ]}
        />
        <div className={styles.title}>{store.complaint?.target.name}</div>
      </div>

      <div className={styles.mainContentContainer}>
        <main className={styles.mainContent}>
          {renderResultSection()}

          <ComplaintContentTable complaint={store.complaint} />

          {renderBottomButton()}
        </main>
      </div>

    </div>
  );
});

ComplaintDetailPage.propTypes = {
  router: PropTypes.object,
  profile: PropTypes.object
};

export default withProfile(ComplaintDetailPage);
