import React from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import { AppStateContext } from 'src/stores';
import { ReactComponent as LogoutIcon } from 'src/assets/logout.svg';
import { ReactComponent as MessageIcon } from 'src/assets/message.svg';
import { ReactComponent as ComplaintIcon } from 'src/assets/blacklist.svg';
import Breadcrumb from 'src/components/Breadcrumb';
import Card from 'src/components/Card';
import { FosterStatus, Router, UnreadNotificationType } from 'src/constants';
import { parseDate } from 'src/utils';

import UserViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class UserCenterPage extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = new UserViewModel(this.props.router);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  goHelp = () => this.props.router.navigate(Router.Client.Help);

  goProfile = () => this.props.router.navigate(Router.Client.Profile);

  getFrequency(cat) {
    const observerPeriod = dayjs(cat.adoptAt).add(cat.observationDuration, 'M').endOf('D');
    const frequency = dayjs().isBefore(observerPeriod) ? cat.observationFrequency : cat.stabilityFrequency;
    return frequency;
  }

  getRibbon(cat) {
    // 已結束
    if (cat.status === FosterStatus.Ended) {
      return {
        label: '已結束',
        color: '#BE185D',
        shadowColor: '#831843'
      };
    }

    // 無需回報，不需標籤
    if (!cat.needReport) return null;

    const frequency = this.getFrequency(cat);

    // 自發回報，不需標籤
    if (frequency === 0) return null;

    return {
      label: `${frequency}天`,
      color: '#52525B',
      shadowColor: '#27272A'
    };
  }

  renderCatList() {
    return (
      <>
        <div className={styles.subtitle}>我認養的喵咪</div>

        <div className={styles.card_box}>
          {this.vm.catList.map((cat) => {
            const isLate = dayjs().isAfter(dayjs(cat.nextReportingAt));
            return (
              <Card
                key={cat.id}
                image={cat.images?.[0]}
                title={cat.name || '-'}
                ribbon={this.getRibbon(cat)}
                content={(
                  <div className={styles.card_content}>
                    <div>
                      {`出養人：${cat.fosterPerson?.nickname ?? ''}`}
                    </div>
                    <div>
                      {`上次更新時間：${parseDate(cat.lastReportingAt, '-', true)}`}
                    </div>
                  </div>
                )}
                footer={(
                  <div className={styles.card_footer}>
                    <div className={styles.card_footer_left}>
                      <div className={styles.card_footer_text}>下次回報截止日：</div>
                      <div className={isLate ? styles.red : styles.blue}>
                        {`${parseDate(cat.nextReportingAt, '-', true)}`}
                        <span>{isLate ? '已遲交！' : ''}</span>
                      </div>
                    </div>
                    <Link
                      className={styles.card_btn}
                      to={`${Router.Client.Report_ROOT}/${cat.id}`}
                    >
                      幸福回報
                    </Link>
                  </div>
                 )}
              />
            );
          })}
        </div>
      </>
    );
  }

  renderNoCat() {
    return (
      <div className={styles.noCatBox}>
        <div className={styles.noCat}>沒有認養的喵咪</div>
      </div>
    );
  }

  render() {
    const { state } = this.context;

    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心' }
          ]}
          className={styles.breadcrumb}
        />

        <div className={styles.header}>
          <div className={styles.head_box}>

            <div className={styles.stickerBox}>
              <Link to={Router.Client.UserEvent} className={styles.sticker}>
                <div className={styles.contentTicket}>
                  <div>抽獎券</div>
                  <div className={styles.primary}>{this.props.profile.ticketCount ?? ''}</div>
                  <div className={styles.light}>張</div>
                </div>
                <CaretRightOutlined className={styles.arrow} />
              </Link>
            </div>

            <div className={styles.squareBox}>
              <div className={styles.squareRow}>
                <Link className={styles.square} to={Router.Client.FosterList}>
                  <div className={styles.dataCountWrapper}>
                    {state.unreadCount[UnreadNotificationType.Foster] > 0 ? (<div className={styles.unread} />) : null}
                    <span className={styles.squareNum}>{this.props.profile.fosterCount}</span>
                    <span className={styles.squareUnit}>筆</span>
                  </div>
                  <div className={styles.squareBottom}>我的出養</div>
                </Link>
                <Link className={styles.square} to={Router.Client.ApplicationList}>
                  <div>
                    <span className={styles.squareNum}>{this.props.profile.applicationCount}</span>
                    <span className={styles.squareUnit}>筆</span>
                  </div>
                  <div className={styles.squareBottom}>我的認養</div>
                </Link>
              </div>

              <div className={styles.squareRow}>
                <Link className={styles.square} to={Router.Client.Comment}>
                  <div className={styles.dataCountWrapper}>
                    {state.unreadCount[UnreadNotificationType.Comment] > 0 ? (<div className={styles.unread} />) : null}
                    <span className={styles.squareNum}>{this.props.profile.commentCount}</span>
                    <span className={styles.squareUnit}>筆</span>
                  </div>
                  <div className={styles.squareBottom}>我的評價</div>
                </Link>
                <Link className={styles.square} to={Router.Client.MessageBox}>
                  <div className={styles.iconWrapper}>
                    <MessageIcon className={styles.icon} />
                    { state.unreadCount[UnreadNotificationType.MessageBox] > 0 ? (<div className={styles.unread} />) : null }
                  </div>
                  <div className={styles.squareBottom}>我的留言板</div>
                </Link>
                <Link className={styles.square} to={Router.Client.Complaint}>
                  <div className={styles.iconWrapper}>
                    <ComplaintIcon className={styles.icon} />
                  </div>
                  <div className={styles.squareBottom}>舉報黑名單</div>
                </Link>
              </div>
            </div>

            <div className={styles.descBox}>
              <div className={styles.desc}>
                <span className={styles.desc_bold}>
                  1. 出養方
                </span>
                看過來！點選「
                <span className={styles.desc_bold}>
                  我的出養
                </span>
                」查看所有跟出養案件相關的內容，包括想要向您認養貓咪的「認養申請」跟「幸福回報」。
              </div>

              <div className={styles.desc}>
                <span className={styles.desc_bold}>
                  2. 認養人
                </span>
                看過來！點選「
                <span className={styles.desc_bold}>
                  我的認養
                </span>
                」可以查看自己想要認養的貓咪的相關管理內容。
              </div>
            </div>


            <div className={styles.others}>
              <Button
                className={styles.btn}
                size="large"
                onClick={this.goProfile}
              >
                帳號管理
              </Button>
              <Button
                className={styles.btn}
                size="large"
                onClick={this.goHelp}
              >
                幫助中心
              </Button>
              <Button
                className={styles.btn}
                size="large"
                icon={<LogoutIcon />}
                onClick={this.vm.logout}
              >
                登出
              </Button>
            </div>
          </div>

        </div>


        <div className={styles.main}>
          <div className={styles.box}>
            {this.vm.hasCat ? this.renderCatList() : this.renderNoCat()}
          </div>
        </div>
      </>
    );
  }
}

UserCenterPage.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withRouter(withProfile(UserCenterPage, true));
