import { request, getHost } from './api';
import AuthService from './auth';

export default class CommentService {

  static async getCommentByUser({ userId, limit, anchor }) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/comment`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        userId,
        anchor,
        limit
      }
    };
    const res = await request(options);
    return res.data;
  }

  static async getCommentById(commentPairId) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/comment/${commentPairId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

  static async replyComment({ commentPairId, commentId, content }) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/comment/${commentPairId}/${commentId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        content
      }
    };
    const res = await request(options);
    return res.data;
  }

  static async getReplyByComment({ commentPairId, commentId, limit, anchor }) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/comment/${commentPairId}/${commentId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        limit,
        anchor
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] get unread count
   */
  static async getUnreadCount() {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/comment/unread`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

  /**
   * [C] mark as read
   */
  static async markAllAsRead() {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/comment/read-all`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data;
  }

}
