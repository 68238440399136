import React, { useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import isMobile from 'ismobilejs';

import { UnreadNotificationType } from 'src/constants';
import { actions } from './actions';
import { reducer } from './reducer';

const initialState = {
  isMobile: isMobile(window.navigator).any,
  profile: null,
  unreadCount: {
    [UnreadNotificationType.MessageBox]: 0,
    [UnreadNotificationType.Foster]: 0,
    [UnreadNotificationType.Comment]: 0
  }
};

const AppStateContext = React.createContext();
AppStateContext.displayName = 'AppState Context';

const AppStateProvider = (props) => {
  const [appState, dispatch] = useReducer(reducer, initialState);

  const acts = useMemo(() => actions(dispatch), [dispatch]);
  const value = useMemo(
    () => ({
      state: appState,
      actions: acts
    }),
    [appState, acts]
  );

  return (
    <AppStateContext.Provider value={value}>
      { props.children }
    </AppStateContext.Provider>
  );
};

AppStateProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AppStateProvider, AppStateContext };
