import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ComplaintIssueTxt, ComplaintLevelTxt } from 'src/constants';
import { parseDate } from 'src/utils';

import styles from './styles.module.scss';

const ComplaintRecordCard = ({ complaint, onClickViewDetail }) => {
  const { event, result } = complaint;
  return (
    <div
      className={styles.card}
    >
      <div className={styles.topRow}>
        <div>
          事件發生時間：
          {parseDate(event.date)}
        </div>
        <div className={clsx(styles.levelTag, styles[result.level])}>
          {ComplaintLevelTxt[result.level]}
        </div>
      </div>
      <div className={styles.issues}>
        {event.issues?.map((issue) => (
          <div
            key={issue}
            className={styles.issue}
          >
            {ComplaintIssueTxt[issue]}
          </div>
        ))}
      </div>
      <div className={styles.description}>
        {result.description}
      </div>
      <div className={styles.footer}>
        {result.matchPercentage ? (
          <div>
            <span>
              資料吻合程度：
            </span>
            <span>
              {`${result.matchPercentage}%`}
            </span>
          </div>
        ) : null}
        <Button
          className={styles.viewDetailButton}
          type="primary"
          onClick={onClickViewDetail}
        >
          查看詳情
        </Button>
      </div>
    </div>
  );
};

ComplaintRecordCard.propTypes = {
  complaint: PropTypes.object.isRequired,
  onClickViewDetail: PropTypes.func
};

export default ComplaintRecordCard;
