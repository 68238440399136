import { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Modal, Tooltip } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { ComplaintLevelTxt, ComplaintIssueTxt } from 'src/constants';
import { parseDate } from 'src/utils';
import GridTableItem from 'src/components/GridTable/GridTableItem';
import Loading from 'src/components/Loading';
import ComplaintService from 'src/services/complaint';
import { ReactComponent as InfoIcon } from 'src/assets/infoIcon.svg';

import styles from './styles.module.scss';

const ComplaintRecordDetailModal = ({ id, open, onClose }) => {
  const [formattedData, setFormattedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDetail = async (detailId) => {
      try {
        setIsLoading(true);
        const complaintDetail = await ComplaintService.getDetail(detailId);
        const formatted = getFormattedData(complaintDetail);
        setFormattedData(formatted);
      } catch (error) {
        console.log('error', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchDetail(id);
    } else {
      setFormattedData({});
    }
  }, [id]);

  const getFormattedData = (rawData) => {
    const { event, result } = rawData;

    const formatted = {
      matchPercentage: {
        title: '資料吻合程度',
        value: (
          <div className={styles.matchPercentageValueWrapper}>
            <span>
              {result.matchPercentage ? `${result.matchPercentage}%` : '-'}
            </span>
            <Tooltip
              title="此為該會員曾被舉報案件，經本平台調查後，確認資料符合本人情況的總體判斷，僅供參考。"
              placement="bottom"
              color="#fff"
              overlayInnerStyle={{ color: '#3f3f46', padding: '12px 16px' }}
            >
              <InfoIcon />
            </Tooltip>
          </div>
        ),
        span: 2
      },
      level: {
        title: '嚴重等級',
        value: (
          <div className={clsx(styles.levelTag, styles[result.level])}>
            {ComplaintLevelTxt[result.level]}
          </div>
        ),
        span: 2
      },
      resultDate: {
        title: '調查更新時間',
        value: parseDate(result.date),
        span: 2
      },
      resultDescription: {
        title: '調查描述',
        value: result.description,
        span: 6
      },
      eventDate: {
        title: '發生時間',
        value: parseDate(event.date),
        span: 3
      },
      eventLocation: {
        title: '發生地區',
        value: event.location,
        span: 3
      },
      eventIssues: {
        title: '主要問題',
        value: (
          <div className={styles.issues}>
            {event.issues?.map((issue) => (
              <div
                key={issue}
                className={styles.issue}
              >
                {ComplaintIssueTxt[issue]}
              </div>
            ))}
          </div>
        ),
        span: 6
      },
      eventDescription: {
        title: '事件描述',
        value: event.description,
        span: 6
      },
      ...result.files.length && {
        resultFiles: {
          title: '事件舉證檔案',
          value: (
            <div className={styles.files}>
              {result.files.map((file, index) => (
                <a
                  key={file.name}
                  className={styles.file}
                  href={file.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PaperClipOutlined className={styles.paperclip} />
                  <span>{file.name}</span>
                </a>
              ))}
            </div>
          ),
          span: 6
        }
      }
    };
    return formatted;
  };

  return (
    <Modal
      className={clsx(styles.complaintRecordDetailModal, 'complaintRecordDetailModal')}
      open={open}
      width="min(930px, 90vw)"
      centered
      maskClosable
      onCancel={onClose}
      title={(
        <h4 className={styles.title}>
          舉報詳情
        </h4>
      )}
      footer={(
        <div className={clsx(styles.footer)}>
          <Button
            className={styles.button}
            onClick={onClose}
          >
            關閉
          </Button>
        </div>
      )}
    >
      {isLoading
        ? (
          <Loading className={styles.loadingBox} />
        ) : (
          <div className={styles.table}>
            {Object.values(formattedData).map((item) => (
              <GridTableItem
                key={item.title}
                title={item.title}
                value={item.value}
                span={item.span}
                className={styles.gridItem}
                labelClassName={styles.gridItemLabel}
              />
            ))}
          </div>
        )}
    </Modal>
  );
};

ComplaintRecordDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string
};

export default ComplaintRecordDetailModal;
