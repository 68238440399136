import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { UnreadNotificationType } from 'src/constants';
import CommentService from 'src/services/comment';
import UserService from 'src/services/user';
import infoStore from 'src/stores/infoStore';

export default class CommentVM {
  userId = null;
  anchor = null;
  limit = 20;
  context = {};
  @observable user;
  @observable commentPairs = [];
  @observable isLoading = false;
  @observable isPageLoading = false;

  constructor(userId) {
    makeObservable(this);
    this.userId = userId;
  }

  @computed get hasComment() {
    return this.commentPairs.length > 0;
  }

  didMount = async (context) => {
    this.context = context;

    try {
      runInAction(() => {
        this.isPageLoading = true;
        this.isLoading = true;
      });

      const { list, anchor } = await CommentService.getCommentByUser({
        userId: this.userId,
        limit: this.limit
      });

      const user = await UserService.getUserById(this.userId);

      runInAction(() => {
        this.commentPairs = list;
        this.anchor = anchor;
        this.isLoading = false;
        this.isPageLoading = false;
        this.user = user;
      });

      if (this.context?.state?.unreadCount[UnreadNotificationType.Comment] > 0) {
        this.markAllCommentsAsRead();
      }

    } catch (err) {
      runInAction(() => {
        this.isPageLoading = false;
        this.isLoading = false;
      });
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };


  onNextPage = async () => {
    if (this.isLoading || !this.anchor) return;

    try {
      runInAction(() => { this.isLoading = true; });

      const { list, anchor } = await CommentService.getCommentByUser({
        userId: this.userId,
        limit: this.limit,
        anchor: this.anchor
      });

      runInAction(() => {
        this.commentPairs = this.commentPairs.concat(list);
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch {
      infoStore.show({ message: '發生錯誤，無法取得資訊', type: 'warn' });
    }
  };

  markAllCommentsAsRead = async () => {
    try {
      await CommentService.markAllAsRead();
      this.context.actions?.updateUnreadCount && this.context.actions.updateUnreadCount(UnreadNotificationType.Comment, 0);
    } catch (err) {
      console.log(err);
    }
  };
}
