export const Router = {
  Client: {
    Home: '/home',
    AboutUs: '/about-us',
    SuccessStories: '/success-stories',
    MessageBox: '/message',
    Message: '/message/:boxId',
    FAQ: '/faq',
    Help: '/help',
    PrivacyPolicy: '/privacy-policy',

    EventList: '/events',
    EventItem: '/events/:raffleEventId',

    NewsItem_ROOT: '/news',
    NewsItem: '/news/:newsId',

    CatList: '/cats',
    CatItem: '/cats/:fosterId',
    CatApplication: '/cats/:fosterId/apply',

    PreviewContract_ROOT: '/preview',
    PreviewContract: '/preview/:fosterId',

    User: '/user',
    Profile: '/user/profile',
    RestPwd: '/user/reset-password',
    FosterList: '/user/foster',
    FosterItem: '/user/foster/:fosterId',
    CreateFosterItem: '/user/foster/create',
    FosterItemApplication: '/user/foster/:fosterId/application/:applicationId',
    UserEvent: '/user/event',
    Report_ROOT: '/user/report',
    Report: '/user/report/:fosterId',
    ApplicationList: '/user/application',
    ApplicationItem: '/user/application/:applicationId',
    Comment: '/user/comment',
    CommentUser: '/user/:userId/comment',
    CommentReply: '/user/:userId/comment/:commentPairId/:commentId',
    Complaint: '/user/complaint',
    ComplaintDetail: '/user/complaint/:complaintId',
    ComplaintCreate: '/user/complaint/create'
  },
  Backstage: {
    User: '/backstage/users',
    UserItem: '/backstage/users/:userId',
    Foster: '/backstage/foster',
    Report: '/backstage/report',
    Raffle: '/backstage/raffle',
    CreateRaffleEvent: '/backstage/raffle/create',
    RaffleEvent: '/backstage/raffle/:raffleId',
    Banner: '/backstage/banner',
    News: '/backstage/news',
    AboutUs: '/backstage/about-us',
    FAQ: '/backstage/faq',
    Help: '/backstage/help',
    Complaint: '/backstage/complaint',
    ComplaintDetail: '/backstage/complaint/:complaintId'
  },
  Auth: {
    Login: '/sign-in',
    Verify: '/verify',
    Register: '/register',
    RestPwd: '/reset-password'
  }
};

export const VerificationType = {
  RestPwd: 'reset-password',
  Register: 'register'
};

export const CommentType = {
  MatchedComment: 'matchedComment',
  CanceledComment: 'canceledComment'
};

export const FosterSort = {
  LastHitShelvesAt: 'LastHitShelvesAt',
  Completeness: 'Completeness'
};

export const EditorContentType = {
  AboutUs: 'AboutUs', // 關於我們
  FAQ: 'FAQ', // 常見問答
  Help: 'Help' // 幫助中心
};

export const FosterSortTxt = {
  LastHitShelvesAt: '最後上架時間',
  Completeness: '完整度'
};


export const FosterStatus = {
  Available: 'Available',
  Unavailable: 'Unavailable',
  Ended: 'Ended',
  Adopted: 'Adopted'
};
export const FosterStatusTxt = {
  Available: '上架中',
  Unavailable: '未上架',
  Ended: '已結束',
  Adopted: '已出養'
};


export const CaseStatus = {
  Ongoing: 'Ongoing',
  Ended: 'Ended'
};

export const CaseStatusTxt = {
  Ongoing: '進行中',
  Ended: '已結束'
};


export const ApplicationStatus = {
  Applied: 'Applied',
  ApplicationCanceled: 'ApplicationCanceled',
  ApplicationDeclined: 'ApplicationDeclined',
  InDiscussion: 'InDiscussion',
  UnderReview: 'UnderReview',
  Matched: 'Matched',
  CancelFosterConfirmation: 'CancelFosterConfirmation',
  CancelAdoptionConfirmation: 'CancelAdoptionConfirmation',
  CancelFoster: 'CancelFoster',
  CancelAdoption: 'CancelAdoption',
  Ended: 'Ended',
  Expired: 'Expired',
  InContract: 'InContract'
};
export const ApplicationStatusTxt = {
  Applied: '已申請',
  ApplicationCanceled: '申請取消',
  ApplicationDeclined: '已婉拒',
  InDiscussion: '聯繫中',
  UnderReview: '核對中',
  Matched: '媒合成功',
  CancelFosterConfirmation: '出養取消確認',
  CancelAdoptionConfirmation: '退養確認',
  CancelFoster: '出養取消',
  CancelAdoption: '退養',
  Ended: '已結束',
  Expired: '已逾期',
  InContract: '簽約中'
};

export const ApplicationAction = {
  /// 出養人
  Contact: 'Contact', // 聯繫
  ConfirmApplication: 'ConfirmApplication', // 確認出養
  DeclineApplication: 'DeclineApplication', // 婉拒申請
  Designation: 'Designation', // 指定 (進階洽談)
  CancelFoster: 'CancelFoster', // 取消出養
  RefuseCancelAdoption: 'RefuseCancelAdoption', // 拒絕退養
  ConfirmCancelAdoption: 'ConfirmCancelAdoption', // 同意退養

  /// 認養人
  CancelApplication: 'CancelApplication', // 取消認養申請
  ReturnContract: 'ReturnContract', // 退回文件
  ConfirmAdoption: 'ConfirmAdoption', // 確認認養
  CancelAdoption: 'CancelAdoption', // 退養
  RefuseCancelFoster: 'RefuseCancelFoster', // 拒絕取消出養
  ConfirmCancelFoster: 'ConfirmCancelFoster', // 同意取消出養
  DeathReport: 'DeathReport' // 死亡通報
};


export const TaiwanCountyTxt = { // 城市代碼
  KLU: '基隆市',
  TPH: '新北市',
  TPE: '台北市',
  TYC: '桃園市',
  HSH: '新竹縣',
  HSC: '新竹市',
  MAL: '苗栗縣',
  TXG: '臺中市',
  CWH: '彰化縣',
  NTO: '南投縣',
  YLH: '雲林縣',
  CHY: '嘉義縣',
  CYI: '嘉義市',
  TNN: '臺南市',
  KHH: '高雄市',
  IUH: '屏東縣',
  ILN: '宜蘭縣',
  HWA: '花蓮縣',
  TTT: '臺東縣',
  PEH: '澎湖縣',
  KMN: '金門縣',
  LNN: '連江縣'
};


export const Gender = {
  Boy: 'Boy',
  Girl: 'Girl',
  Unknown: 'Unknown'
};
export const GenderTxt = {
  Boy: '是男森',
  Girl: '是女森',
  Unknown: '還不確定'
};

export const Coloration = {
  White: 'White',
  Black: 'Black',
  Orange: 'Orange',
  Gray: 'Gray',
  Tabby: 'Tabby', // 虎斑
  Dots: 'Dots', // 斑點
  MixedColor: 'MixedColor' // 混色
};
export const ColorationTxt = {
  White: '白(顏色)',
  Black: '黑(顏色)',
  Orange: '橘(顏色)',
  Gray: '灰(顏色)',
  Tabby: '虎斑 (花紋)',
  Dots: '斑點 (花紋)',
  MixedColor: '混色 (花紋)'
};

export const AidsTest = {
  NotChecked: 'NotChecked', // 未檢查
  None: 'None', // 無愛滋無白血
  Leukemia: 'Leukemia', // 僅有白血
  Aids: 'Aids', // 僅有愛滋
  Both: 'Both' // 愛滋及白血
};
export const AidsTestTxT = {
  NotChecked: '未檢查',
  None: '無愛滋無白血',
  Leukemia: '僅有白血',
  Aids: '僅有愛滋',
  Both: '愛滋及白血'
};

export const Vaccine = {
  NotVaccinated: 'NotVaccinated', // 未施打
  ThreeInOne: 'ThreeInOne', // 三合一
  FourInOne: 'FourInOne', // 四合一
  FiveInOne: 'FiveInOne' // 五合一
};
export const VaccineTxt = {
  NotVaccinated: '未施打',
  ThreeInOne: '三合一',
  FourInOne: '四合一',
  FiveInOne: '五合一'
};

export const Dewormer = {
  NotImplemented: 'NotImplemented', // 未施作
  FrontlineSpray: 'FrontlineSpray', // 蚤不到噴劑
  FrontlineDrops: 'FrontlineDrops', // 蚤不到滴劑
  Revolution: 'Revolution', // 寵愛
  Broadline: 'Broadline', // 全能貓
  SulfurBath: 'SulfurBath', // 硫磺浴
  Others: 'Others'
};
export const DewormerTxt = {
  NotImplemented: '未施作',
  FrontlineSpray: '蚤不到噴劑',
  FrontlineDrops: '蚤不到滴劑',
  Revolution: '寵愛',
  Broadline: '全能貓',
  SulfurBath: '硫磺浴',
  Others: '其他'
};

export const Behavior = {
  Aggressive: 'Aggressive', // 具攻擊性
  LessLikelyToSurvive: 'LessLikelyToSurvive', // 年幼不易存活
  Howl: 'Howl', // 嚎叫
  HateOtherAnimals: 'HateOtherAnimals', // 不喜歡其它動物
  ProneToNervous: 'ProneToNervous', // 容易緊張
  HasPica: 'HasPica', // 有異食癖
  Others: 'Others'
};
export const BehaviorTxt = {
  Aggressive: '具攻擊性',
  LessLikelyToSurvive: '年幼不易存活',
  Howl: '嚎叫',
  HateOtherAnimals: '不喜歡其它動物',
  ProneToNervous: '容易緊張',
  HasPica: '有異食癖',
  Others: '其他'
};

export const NotificationDeadlineTxt = {
  0: '不需',
  1: '1天內',
  3: '3天內',
  7: '7天內'
};
export const ObservationDurationTxt = {
  1: '一個月',
  3: '三個月',
  6: '六個月',
  12: '十二個月',
  24: '二十四個月'
};
export const ObservationFrequencyTxt = {
  7: '七天',
  14: '十四天',
  30: '三十天'
};
export const StabilityFrequencyTxt = {
  0: '自發回報',
  14: '十四天',
  30: '三十天',
  60: '六十天',
  90: '九十天'
};
export const trialPeriodTxt = {
  0: '沒有適應期',
  14: '十四天',
  30: '三十天',
  60: '六十天'
};

export const ComplaintIssue = {
  BasicNeedsNeglect: 'basic-needs-neglect',
  PhysicalHarm: 'physical-harm',
  PsychologicalHarm: 'psychological-harm',
  UnfitEnvironment: 'unfit-environment',
  ImproperBreeding: 'improper-breeding',
  ImproperCustody: 'improper-custody',
  ImproperCare: 'improper-care',
  ContractNeglect: 'contract-neglect'
};

export const ComplaintIssueTxt = {
  [ComplaintIssue.BasicNeedsNeglect]: '忽視寵物基本生理需求',
  [ComplaintIssue.PhysicalHarm]: '直接或間接傷害寵物身體',
  [ComplaintIssue.PsychologicalHarm]: '直接或間接傷害寵物心理',
  [ComplaintIssue.UnfitEnvironment]: '未能提供合理合法之環境',
  [ComplaintIssue.ImproperBreeding]: '不當繁殖或未依法絕育',
  [ComplaintIssue.ImproperCustody]: '未依法妥善保管寵物',
  [ComplaintIssue.ImproperCare]: '未依法妥善照顧寵物',
  [ComplaintIssue.ContractNeglect]: '未依議定合約照顧寵物'
};

export const ComplaintStatus = {
  NotStarted: 'not-started',
  InProcess: 'in-process',
  Completed: 'completed'
};

export const ComplaintStatusTxt = {
  [ComplaintStatus.NotStarted]: '尚未處理',
  [ComplaintStatus.InProcess]: '調查中',
  [ComplaintStatus.Completed]: '已調查'
};

export const ComplaintLevel = {
  High: 'high',
  Low: 'low',
  Medium: 'medium',
  Uncertain: 'uncertain', // 無法判斷（e.g.證據不足）
  Unrated: 'unrated' // 不評級（e.g.已有重複舉報故未調查）
};

export const ComplaintLevelTxt = {
  [ComplaintLevel.Low]: '輕度',
  [ComplaintLevel.Medium]: '中度',
  [ComplaintLevel.High]: '重度',
  [ComplaintLevel.Uncertain]: '無法判斷',
  [ComplaintLevel.Unrated]: '不評級'
};

export const UnreadNotificationType = {
  MessageBox: 'messageBox',
  Foster: 'foster',
  Comment: 'comment'
};
