import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import Breadcrumb from 'src/components/Breadcrumb';
import GridTable from 'src/components/GridTable';
import Loading from 'src/components/Loading';
import NoPermission from 'src/components/NoPermission';
import clsx from 'clsx';
import {
  Router,
  ComplaintStatusTxt,
  ComplaintLevelTxt,
  ComplaintStatus
} from 'src/constants';
import { parseDate } from 'src/utils';
import ComplaintContentTable from 'src/components/ComplaintContentTable';

import ComplaintDetailVM from './viewModel';
import styles from './styles.module.scss';


@observer
class ComplaintDetailPage extends React.Component {
  constructor(props) {
    super(props);
    const complaintId = this.props.router.params.complaintId;
    this.vm = new ComplaintDetailVM(complaintId, this.props.profile);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  renderResultSection = () => {
    const { result } = this.vm.complaint;
    const isStatusCompleted = this.vm.status === ComplaintStatus.Completed;
    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.sectionTitle}>
            平台調查結果
          </div>
          <div className={clsx(styles.statusTag, styles[this.vm.status])}>
            {ComplaintStatusTxt[this.vm.status]}
          </div>
        </div>
        <div className={styles.content}>
          <GridTable
            shortList={[
              {
                key: '資料吻合程度',
                value: result.matchPercentage ? `${result.matchPercentage}%` : '-'
              },
              {
                key: '嚴重等級',
                value: isStatusCompleted
                  ? (
                    <div className={clsx(styles.levelTag, styles[result.level])}>
                      {ComplaintLevelTxt[result.level]}
                    </div>
                  )
                  : '-'
              },
              { key: '更新時間', value: isStatusCompleted ? parseDate(result.date) : '-' }
            ]}
            shortListClassName={styles.threeColGrid}
            labelClassName={styles.shortListLabel}
            longList={[
              { key: '調查描述', value: isStatusCompleted ? result.description : '-' }
            ]}
          />
        </div>
      </section>
    );
  };

  render() {
    if (this.vm.isPageLoading || !this.vm.hasPermission) {
      return (
        <>
          {this.vm.isPageLoading && <Loading />}
          {!this.vm.isPageLoading && <NoPermission callback={() => this.props.router.navigate(Router.Client.Home)} />}
        </>
      );
    }

    return (
      <>
        <Breadcrumb
          items={[
            { title: '會員中心', link: Router.Client.User },
            { title: '舉報黑名單', link: Router.Client.Complaint },
            { title: '詳情' }
          ]}
        />

        <div className={styles.main}>
          <div className={styles.box}>
            {this.vm.complaint && (
              <>
                {this.renderResultSection()}
                <ComplaintContentTable
                  complaint={this.vm.complaint}
                  layout="client"
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

ComplaintDetailPage.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object
};


export default withRouter(withProfile(ComplaintDetailPage, true));
